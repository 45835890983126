import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import soeLogo from '../../images/soe-logo.svg';
import { BaseUrl } from '../../constants';
import { beamFormViewed } from '../../utils/beam';
import useIntersection from '../../utils/useIntersection';

function WidgetWrapper({ children, customerStatus, step }) {
  const unserviceAbleFlow = customerStatus.error && step > 1;
  const widgetRef = useRef(null);
  const isWidgetInViewport = useIntersection(widgetRef);
  const [beamFired, setBeamFired] = useState(false);

  useEffect(() => {
    // fire Beam if it's in the viewport and only fire it once
    if (isWidgetInViewport && !beamFired) {
      beamFormViewed();
      setBeamFired(true);
    }
  }, [isWidgetInViewport]);

  return (
    <div
      id="widgetWrapper"
      ref={widgetRef}
      className={`WidgetGroup ${unserviceAbleFlow ? 'WidgetGroup__unserviceable-flow' : ''}`}
    >
      <div className="WidgetGroup__content WidgetGroup__content--cobranded">
        <div className="WidgetGroup__content-inner">
          {children}
        </div>
        <img
          className="WidgetGroup__logo"
          alt="Powered By Save On Energy"
          src={`${BaseUrl}${soeLogo}`}
        />
      </div>
    </div>
  );
}

WidgetWrapper.defaultProps = {
  customerStatus: {},
  step: 0,
};

WidgetWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  customerStatus: PropTypes.shape({
    error: PropTypes.string,
  }),
  step: PropTypes.number,
};

export default WidgetWrapper;
