import React from 'react';
import PropTypes from 'prop-types';
import { Status, Error } from '../../../constants';

function UnserviceableSuccessStep({ activeSlide, step, customerStatus }) {
  const { current, previous } = activeSlide;
  const { status, error } = customerStatus;
  const unserviceAble = status === Status.UNSERVICEABLE;
  const isRenter = error === Error.RENTER;

  const copy = () => {
    if (isRenter) {
      return (
        <>
          <h2
            className="slide__heading unserviceable-heading"
          >
            Thanks! We’ll share the latest solar options for your home as we partner with more companies.
          </h2>
        </>
      );
    }
    return (
      <>
        <h2
          className="slide__heading unserviceable-heading"
        >
          Thanks! We’ll share the latest solar options in your area as we partner with more companies.
        </h2>
      </>
    );
  };

  return (
    <div className={`slide slide-unserviceable slide-unserviceable--success
     ${step === previous && unserviceAble ? 'is-inactive' : ''}
     ${step === current && unserviceAble ? 'is-active' : ''}`}
    >
      {copy()}
    </div>
  );
}

UnserviceableSuccessStep.defaultProps = {
  previous: 0,
  current: 0,
  activeSlide: {},
  customerStatus: {},
};

UnserviceableSuccessStep.propTypes = {
  current: PropTypes.number,
  previous: PropTypes.number,
  step: PropTypes.number.isRequired,
  activeSlide: PropTypes.shape(),
  customerStatus: PropTypes.shape({
    status: PropTypes.string,
    error: PropTypes.string,
  }),
};

export default UnserviceableSuccessStep;
