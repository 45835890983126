import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Validation } from '../../../constants';
import { beam } from '../../../utils/beam';
import errorIcon from '../../../images/error-icon.svg';

function Phone({
  labelText,
  placeHolder,
  setValidate,
  valid,
  formValidation,
  customerData,
  setCustomerData,
}) {
  const [inputValue, setInputValue] = useState('');
  const [phoneInvalid, setPhoneInvalid] = useState(false);

  function phoneMask(value) {
    const rawValue = value.replace(/\D/g, '');

    const input = rawValue.substring(0, 10);
    const area = rawValue.substring(0, 3);
    const prefix = rawValue.substring(3, 6);
    const line = rawValue.substring(6, 10);

    if (input.length > 6) {
      return `(${area}) ${prefix}-${line}`;
    }

    if (input.length > 3) {
      return `(${area}) ${prefix}`;
    }

    return input.length > 0 ? `(${area}` : '';
  }

  const nanpPhoneValidation = (phone) => {
    const phoneVal = phone.replace(/[^0-9]/g, '');
    const nanpValidation = /^[2-9][0-8]\d[2-9][0-9][0-9]\d{4}$/;

    const isPhoneValid = nanpValidation.test(phoneVal);

    if (!isPhoneValid) {
      setValidate({ ...valid, phone: Validation.INVALID });
      return setPhoneInvalid(true);
    }

    // Ensure digits 2 and 3 do not match each other
    const twoAndThreeMatch = phoneVal.charAt(1) === phoneVal.charAt(2);

    // Ensure digits 5 and 6 are not both 1's
    const fiveAndSixAreOnes = phoneVal.charAt(4) === '1' && phoneVal.charAt(5) === '1';

    // Ensure digits 4, 5, and 6 are not "555" AND 7-10 are not between 0100 and 0199
    const last3 = phoneVal.substring(7);
    const last3AsNum = Number(last3);

    const invalid555 = phoneVal.charAt(4) === '5' && phoneVal.charAt(5) === '5' && phoneVal.charAt(5) === '5';
    const last3Range = (last3AsNum >= 100 && last3AsNum <= 199);
    const invalidNumCheck = invalid555 && last3Range;

    if (twoAndThreeMatch || fiveAndSixAreOnes || invalidNumCheck) {
      setValidate({ ...valid, phone: Validation.INVALID });
      return setPhoneInvalid(true);
    }
    setValidate({ ...valid, phone: Validation.VALID });
    return setPhoneInvalid();
  };

  const handlePhone = (value) => {
    setCustomerData({ ...customerData, phone: value });
    nanpPhoneValidation(value);
  };

  const handlePhoneOnchange = ({ target }) => {
    const { value } = target;
    setInputValue(phoneMask(value));
    handlePhone(value);
  };

  const handlePhoneOnBlur = ({ target }) => {
    const { value } = target;

    beam('FieldInputted', {
      userInputField: {
        fieldType: 'PHONE NUMBER',
        fieldName: 'PHONE NUMBER',
        fieldValue: value,
      },
      formContext: {
        formType: 'PERSONAL INFO',
        formName: 'PERSONAL INFO',
      },
    });
    handlePhone(value);
  };

  useEffect(() => {
    if (customerData.phone.length === 10) {
      handlePhone(customerData.phone);
    }
  }, [customerData.phone]);

  return (
    <div className="Input">
      <span
        className="Input__label Input__label--cnet"
        htmlFor={labelText}
      >
        {labelText}
      </span>
      <input
        className={`Input__input Input__input--cnet
          ${formValidation === Validation.INVALID ? 'Input__input--error' : ''}`}
        id={labelText}
        type="text"
        placeholder={placeHolder}
        onChange={(e) => handlePhoneOnchange(e)}
        onBlur={(e) => handlePhoneOnBlur(e)}
        value={inputValue}
        autoComplete="off"
      />
      {phoneInvalid && (
        <span className="Input__input--error-message">
          <img
            src={errorIcon}
            alt="yield icon"
            className="Icon-error"
          />
          Please enter a valid phone number
        </span>
      )}
    </div>
  );
}

Phone.defaultProps = {
  labelText: '',
  setValidate: () => {},
  valid: {},
  formValidation: '',
  customerData: {},
  setCustomerData: () => {},
};

Phone.propTypes = {
  placeHolder: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  setValidate: PropTypes.func,
  valid: PropTypes.shape(),
  formValidation: PropTypes.string,
  customerData: PropTypes.shape(),
  setCustomerData: PropTypes.func,
};

export default Phone;
