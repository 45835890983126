import React from 'react';
import PropTypes from 'prop-types';
import animatedSvg from '../../../images/animated-svg.svg';
import { BaseUrl } from '../../../constants';

function UnserviceableSuccessStep({ activeSlide, step }) {
  const { current, previous } = activeSlide;

  const copy = () => (
    <>
      <h2
        className="slide__heading slide__heading--cnet"
      >
        Thank you!
      </h2>
      <p
        className="slide__description slide__description--cnet"
      >
        Check your inbox for an email from us with more information on what to expect next.
      </p>
    </>
  );

  return (
    <form className={`slide slide-serviceable--success
     ${step === previous ? 'is-inactive' : ''}
     ${step === current ? 'is-active' : ''}`}
    >
      <object
        className="slide__animated-svg"
        type="image/svg+xml"
        data={`${BaseUrl}${animatedSvg}`}
      >
        svg-animation
      </object>
      {copy()}
    </form>
  );
}

UnserviceableSuccessStep.defaultProps = {
  previous: 0,
  current: 0,
  activeSlide: {},
  customerStatus: {},
};

UnserviceableSuccessStep.propTypes = {
  current: PropTypes.number,
  previous: PropTypes.number,
  step: PropTypes.number.isRequired,
  activeSlide: PropTypes.shape(),
  customerStatus: PropTypes.shape({
    status: PropTypes.string.isRequired,
    error: PropTypes.string,
  }),
};

export default UnserviceableSuccessStep;
