import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Validation } from '../../../constants';
import { beam } from '../../../utils/beam';

function FirstName({
  labelText,
  placeHolder,
  setValidate,
  valid,
  formValidation,
  customerData,
  setCustomerData,
}) {
  const [inputValue, setInputValue] = useState('');

  const handleFirstName = (value) => {
    setCustomerData({ ...customerData, firstName: value });

    if (value) {
      setValidate({ ...valid, firstName: Validation.VALID });
    } else {
      setValidate({ ...valid, firstName: Validation.INVALID });
    }
  };

  const handleFirstNameOnchange = ({ target }) => {
    const { value } = target;
    setInputValue(value);
    handleFirstName(value);
  };

  const handleFirstNameOnBlur = ({ target }) => {
    const { value } = target;

    beam('FieldInputted', {
      userInputField: {
        fieldType: 'FIRST NAME',
        fieldName: 'FIRST NAME',
        fieldValue: value,
      },
      formContext: {
        formType: 'PERSONAL INFO',
        formName: 'PERSONAL INFO',
      },
    });

    if (value) {
      setValidate({ ...valid, address: Validation.VALID });
    } else {
      setValidate({ ...valid, address: Validation.INVALID });
    }
  };

  return (
    <div className="Input">
      <span
        className="Input__label Input__label--cnet"
        htmlFor={labelText}
      >
        {labelText}
      </span>
      <input
        className={`Input__input Input__input--cnet
          ${formValidation === Validation.INVALID ? 'Input__input--error' : ''}`}
        id={labelText}
        type="text"
        placeholder={placeHolder}
        onChange={(e) => handleFirstNameOnchange(e)}
        onBlur={(e) => handleFirstNameOnBlur(e)}
        value={inputValue}
        autoComplete="off"
      />
    </div>
  );
}

FirstName.defaultProps = {
  labelText: '',
  setValidate: () => {},
  valid: {},
  formValidation: '',
  customerData: {},
  setCustomerData: () => {},
};

FirstName.propTypes = {
  placeHolder: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  setValidate: PropTypes.func,
  valid: PropTypes.shape(),
  formValidation: PropTypes.string,
  customerData: PropTypes.shape(),
  setCustomerData: PropTypes.func,
};

export default FirstName;
