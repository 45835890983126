import React, { useCallback, useEffect, useState } from 'react';
import CustomerEligibility from './components/pages/CustomerEligibilityCheck';
import UnserviceableEmailStep from './components/pages/UnserviceableEmailStep';
import UnserviceableSuccessStep from './components/pages/UnserviceableSuccessStep';
import HomeInformation from './components/pages/HomeInformation';
import CustomerInfo from './components/pages/CustomerInfo';
import CommunitySolar from './components/pages/CommunitySolar';
import CommunitySolarRedirectStep from './components/pages/CommunitySolarRedirectStep';
import WidgetWrapper from './components/WidgetWrapper';
import SuccessPage from './components/pages/SuccessPage';
import { Status, Error } from './constants';
import AppState from './utils/appState.json';
import './scss/project.scss';
import ProgressTracker from './components/ProgressTracker';
import { beamFormViewed } from './utils/beam';

function App() {
  const [activeSlide, setActiveSlide] = useState({ current: 1, previous: 0 });
  const [customerStatus, setCustomerStatus] = useState({ status: '', error: '' });
  const [customerData, setCustomerData] = useState(AppState);

  const { providers, rentOrOwn } = customerData;

  const rooftopSolarProviders = ['Lumio', 'Palmetto', 'Sunpower'];
  const hasRooftopSolar = rooftopSolarProviders.filter((provider) => providers.includes(provider));

  const hasCommunitySolar = providers.includes('Arcadia');

  const getMessage = useCallback((status, error) => {
    const message = ['Lead Form'];

    if (status) {
      message.push(status);
    }

    if (error) {
      message.push(error);
    }

    return message.join(' - ');
  }, []);

  useEffect(() => {
    if (activeSlide?.current === 2) {
      const message = getMessage(Status[customerStatus?.status], Error[customerStatus?.error]);
      beamFormViewed(message);
    }
  }, [activeSlide, customerStatus]);

  return (
    <div className="App">
      <WidgetWrapper
        customerStatus={customerStatus}
        step={activeSlide.current}
      >
        <ProgressTracker
          step={activeSlide.current}
          customerData={customerData}
          customerStatus={customerStatus}
        />
        {/* serviceable flow */}
        <CustomerEligibility
          step={1}
          setActiveSlide={setActiveSlide}
          activeSlide={activeSlide}
          setCustomerStatus={setCustomerStatus}
          setCustomerData={setCustomerData}
          customerData={customerData}
          customerStatus={customerStatus}
        />
        {/* Serviceable AND is rooftop solar (Palmetto) provider (based off zip) AND OWNER */}
        {(hasRooftopSolar.length > 0)
        && rentOrOwn === 'OWNER'
        && customerStatus.status === Status.SERVICEABLE
        && (
          <>
            <HomeInformation
              step={2}
              customerStatus={customerStatus}
              activeSlide={activeSlide}
              setActiveSlide={setActiveSlide}
              setCustomerData={setCustomerData}
              customerData={customerData}
            />
            <CustomerInfo
              step={3}
              customerStatus={customerStatus}
              activeSlide={activeSlide}
              setActiveSlide={setActiveSlide}
              setCustomerData={setCustomerData}
              customerData={customerData}
            />
            <SuccessPage
              step={4}
              customerStatus={customerStatus}
              activeSlide={activeSlide}
              setActiveSlide={setActiveSlide}
              setCustomerData={setCustomerData}
              customerData={customerData}
            />
          </>
        )}
        {/* Serviceable AND is community solar (Arcadia) provider (based off zip) OR RENTER */}
        {((hasCommunitySolar && hasRooftopSolar.length === 0) || rentOrOwn === 'RENTER')
        && customerStatus.status === Status.SERVICEABLE
        && (
          <>
            <CommunitySolar
              step={2}
              customerStatus={customerStatus}
              activeSlide={activeSlide}
              setActiveSlide={setActiveSlide}
              setCustomerData={setCustomerData}
              customerData={customerData}
            />
            <CommunitySolarRedirectStep
              step={3}
              customerStatus={customerStatus}
              activeSlide={activeSlide}
              setActiveSlide={setActiveSlide}
            />
          </>
        )}

        {/* If unserviceable, it's not a Palmetto or Acadia zip */}
        {customerStatus.status === Status.UNSERVICEABLE && (
          <>
            {/* Unserviceable flow */}
            <UnserviceableEmailStep
              step={2}
              customerStatus={customerStatus}
              activeSlide={activeSlide}
              setActiveSlide={setActiveSlide}
              setCustomerData={setCustomerData}
              customerData={customerData}
            />
            <UnserviceableSuccessStep
              step={3}
              customerStatus={customerStatus}
              activeSlide={activeSlide}
              setActiveSlide={setActiveSlide}
            />
          </>
        )}
      </WidgetWrapper>
    </div>
  );
}

export default App;
