import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button';
import { Status } from '../../../constants';

function CommunitySolar({
  activeSlide,
  setActiveSlide,
  step,
  customerStatus,
}) {
  const { current, previous } = activeSlide;
  const { status, error } = customerStatus;
  const isServiceable = !error && status === Status.SERVICEABLE;

  return (
    <div className={`slide
      slide__community-solar-screen
      ${step === previous && isServiceable ? 'is-inactive' : ''}
      ${step === current && isServiceable ? 'is-active' : ''}`}
    >
      <h2
        className="slide__heading community-solar-heading"
      >
        Rooftop solar isn’t available yet
      </h2>
      <p
        className="slide__description slide__description--cnet"
      >
        Community solar may be a clean energy option for you. With it,
        you can access solar whether you rent, own,
        live in an apartment or a house.
      </p>
      <p
        className="slide__question slide__question--community-solar slide__question--community-solar-cnet"
      >
        Check if community solar is available for you
      </p>
      <div className="slide__community-solar--cta">
        <a
        // eslint-disable-next-line max-len
          href="https://www.arcadia.com/lp-saveonenergy?utm_source=saveonenergy&utm_medium=marketplace&promo=saveonenergy0"
          target="_blank"
          rel="noreferrer"
          className="button__link--community-solar"
        >
          <Button
            type="arcadiaBtn"
            aria-label="check availability"
            className="button__community-solar"
            title="Check availability"
            activeSlide={activeSlide}
            setActiveSlide={setActiveSlide}
            customerStatus={customerStatus}
          />
        </a>
      </div>
    </div>
  );
}

CommunitySolar.defaultProps = {
  previous: 0,
  current: 0,
  activeSlide: {},
  setActiveSlide: () => {},
  customerStatus: {},
};

CommunitySolar.propTypes = {
  current: PropTypes.number,
  previous: PropTypes.number,
  step: PropTypes.number.isRequired,
  activeSlide: PropTypes.shape(),
  setActiveSlide: PropTypes.func,
  customerStatus: PropTypes.shape({
    status: PropTypes.string,
    error: PropTypes.string,
  }),
};

export default CommunitySolar;
