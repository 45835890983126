import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button';
import { Status, Validation, Error } from '../../../constants';
import { beam } from '../../../utils/beam';
import Zip from '../../Inputs/Zip';
import BillAmount from '../../Inputs/BillAmount';

function CustomerEligibility({
  step,
  setActiveSlide,
  activeSlide,
  setCustomerStatus,
  setCustomerData,
  customerData,
  customerStatus,
}) {
  const { address, typicalMonthlyElectricBill, rentOrOwn } = customerData;
  const { zip } = address;
  const [valid, setValidate] = useState({ zip: '', typicalMonthlyElectricBill: '', rentOrOwn: '' });
  const { previous } = activeSlide;
  const [buttonDisable, setButtonDisable] = useState(false);

  useEffect(() => {
    // Determines when to enable/disable continue button
    if (zip
      && typicalMonthlyElectricBill
      && rentOrOwn
      && previous === 0
    ) {
      setButtonDisable(true);
      return;
    }
    setButtonDisable(false);
  }, [zip, typicalMonthlyElectricBill, rentOrOwn, previous]);

  const handleButtonChange = (e) => {
    beam('ElementClicked', {
      webElement: {
        location: 'LEAD FORM',
        position: '',
        elementType: 'BUTTON',
        text: e,
        htmlId: '',
      },
    });

    const inValidZip = (customerStatus.error === Error.ZIP && customerStatus.status === Status.UNSERVICEABLE);

    setCustomerData({ ...customerData, rentOrOwn: e });
    setValidate({ ...valid, rentOrOwn: Validation.VALID });
    // If the user adds an invalid zip but has a valid ownership type
    // it is still invalid
    if (inValidZip) return;
    setCustomerStatus({ status: Status.SERVICEABLE, error: '' });
  };

  return (
    <form className={`slide
      ${step === previous ? 'is-inactive' : ''}
      ${previous === 0 ? 'is-active' : ''}`}
    >
      <h2
        className="slide__heading slide__heading--cnet"
      >
        Go solar at home
      </h2>
      <p
        className="slide__description slide__description--cnet"
      >
        We’ll put you in touch with solar providers who can help.
      </p>
      <div className="slide__inputs">
        <Zip
          labelText="ZIP code"
          type="zip"
          placeHolder="ZIP code"
          setValidate={setValidate}
          valid={valid}
          activeSlide={activeSlide}
          formValidation={valid.zip}
          customerData={customerData}
          setCustomerData={setCustomerData}
          setCustomerStatus={setCustomerStatus}
        />
        <BillAmount
          className="Input__input--customer-eligible"
          labelText="Your typical monthly electric bill"
          type="currency"
          setCustomerData={setCustomerData}
          customerData={customerData}
          placeHolder="Your typical electric bill"
          setValidate={setValidate}
          valid={valid}
          formValidation={valid.typicalMonthlyElectricBill}
          toolTip='This can be an estimate. Your monthly charges are usually listed in
          the billing summary section of your bill as "total amount due" or "total new charges."'
        />
      </div>
      <div className="slide__question--container">
        <p
          className="slide__question slide__question--cnet"
        >
          Do you own or rent?
        </p>
        <div className="slide__buttons">
          <Button
            aria-label="own"
            className="button--rent-own"
            title="Owner"
            onChange={(e) => handleButtonChange(e)}
            value="OWNER"
            isActive={rentOrOwn === 'OWNER'}
            formValidation={valid.rentOrOwn}
          />
          <Button
            aria-label="rent"
            className="button--rent-own"
            title="Renter"
            onChange={(e) => handleButtonChange(e)}
            value="RENTER"
            isActive={rentOrOwn === 'RENTER'}
            formValidation={valid.rentOrOwn}
          />
        </div>
      </div>
      <Button
        type="submit"
        aria-label="get started"
        title="Get started"
        setActiveSlide={setActiveSlide}
        activeSlide={activeSlide}
        disabled={!buttonDisable}
      />
    </form>
  );
}

CustomerEligibility.defaultProps = {
  previous: 0,
  activeSlide: {},
  setCustomerData: () => {},
  customerData: {},
  customerStatus: {
    status: '',
    error: '',
  },
};

CustomerEligibility.propTypes = {
  previous: PropTypes.number,
  step: PropTypes.number.isRequired,
  setActiveSlide: PropTypes.func.isRequired,
  activeSlide: PropTypes.shape(),
  setCustomerStatus: PropTypes.func.isRequired,
  setCustomerData: PropTypes.func,
  customerData: PropTypes.shape(),
  customerStatus: PropTypes.shape({
    status: PropTypes.string,
    error: PropTypes.string,
  }),
};

export default CustomerEligibility;
