import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Validation, Status, Error } from '../../../constants';
import { beam } from '../../../utils/beam';
import zipLookUp from '../../../utils/zipLookUp';

function Zip({
  labelText,
  placeHolder,
  setValidate,
  valid,
  formValidation,
  customerData,
  setCustomerData,
  setCustomerStatus,
  activeSlide,
}) {
  const { address } = customerData;
  const { zip } = address;
  const [inputValue, setInputValue] = useState('');
  const [touched, setTouched] = useState(false);

  const validateZip = async (zipNum) => {
    const isValid = await zipLookUp(zipNum);

    if (isValid) {
      setCustomerData({ ...customerData, providers: isValid });
      setCustomerStatus({ status: Status.SERVICEABLE, error: '' });
      return;
    }
    setCustomerStatus({ status: Status.UNSERVICEABLE, error: Error.ZIP });
  };

  const handleZip = (value) => {
    if (value.length === 6) return;

    const state = {
      address: {
        ...address,
        zip: value,
      },
    };

    setInputValue(value);
    setCustomerData({ ...customerData, ...state });

    if (zip.length === 5 && value.length === 5 && activeSlide.current === 1) {
      validateZip(zip);
      setValidate({ ...valid, zip: Validation.VALID });
    } else {
      setValidate({ ...valid, zip: Validation.INVALID });
    }
  };

  const handleZipOnBlur = ({ target }) => {
    const { value } = target;
    const isValid = value.length === 5;

    beam('FieldInputted', {
      userInputField: {
        fieldType: 'ZIP CODE',
        fieldName: 'ZIP CODE',
        fieldValue: value,
      },
      formContext: {
        formType: 'LEAD FORM',
      },
    });

    setTouched(true);

    if (isValid) {
      setValidate({ ...valid, zip: Validation.VALID });
    } else {
      setValidate({ ...valid, zip: Validation.INVALID });
    }
  };

  const handleZipOnFocus = () => {
    beam('FormStarted', {
      formContext: {
        formType: 'LEAD FORM',
        formName: 'ZIP CODE',
        formId: '',
      },
    });
  };

  useEffect(() => {
    if (zip.length === 5) {
      handleZip(zip);
    }
  }, [zip]);

  return (
    <div className="Input">
      <span
        className="Input__label Input__label--cnet"
        htmlFor={labelText}
      >
        {labelText}
      </span>
      <input
        className={`Input__input Input__input--cnet
          ${touched && formValidation === Validation.INVALID ? 'Input__input--error' : ''}`}
        id={labelText}
        type="number"
        maxLength="5"
        placeholder={placeHolder}
        onChange={({ target }) => handleZip(target?.value)}
        onBlur={(e) => handleZipOnBlur(e)}
        onFocus={() => handleZipOnFocus()}
        value={inputValue}
        autoComplete="off"
        onWheel={(e) => e.target.blur()}
      />
    </div>
  );
}

Zip.defaultProps = {
  labelText: '',
  setValidate: () => {},
  valid: {},
  formValidation: '',
  customerData: {},
  setCustomerData: () => {},
  setCustomerStatus: () => {},
  activeSlide: {},
};

Zip.propTypes = {
  placeHolder: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  setValidate: PropTypes.func,
  valid: PropTypes.shape(),
  formValidation: PropTypes.string,
  customerData: PropTypes.shape(),
  setCustomerData: PropTypes.func,
  setCustomerStatus: PropTypes.func,
  activeSlide: PropTypes.shape(),
};

export default Zip;
