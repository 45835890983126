import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';

function ProgressTracker({ step, customerData, customerStatus }) {
  const progressBarRef = useRef(null);
  const unserviceAbleFlow = customerStatus.error && step > 1;
  const { providers, rentOrOwn } = customerData;

  const rooftopSolarProviders = ['Lumio', 'Palmetto', 'Sunpower'];
  const hasRooftopSolar = rooftopSolarProviders.filter((provider) => providers.includes(provider));

  useEffect(() => {
    if (step <= 3 && !unserviceAbleFlow) {
      const progressIndicators = progressBarRef.current?.querySelectorAll('.ProgressTracker__bar');

      for (let i = 0; i < step; i += 1) {
        progressIndicators[i]?.classList.add('is-active', 'is-active--cnet');
      }
    }
  }, [progressBarRef?.current, step]);

  const unserviceable = step > 3 || unserviceAbleFlow;

  const showProgressTracker = () => {
    if (step === 1 || (rentOrOwn === 'OWNER' && hasRooftopSolar.length > 0)) {
      return 'ProgressTracker';
    }
    return 'noProgressTracker';
  };

  return (
    !unserviceable && (
    <div className={showProgressTracker()}>
      <span
        className="ProgressTracker__step-number ProgressTracker__step-number--cnet"
      >
        Step
        {' '}
        {step}
        {' '}
        of 3
      </span>
      <div
        ref={progressBarRef}
        className="ProgressTracker__progress-bar-container"
      >
        <span className="ProgressTracker__bar" />
        <span className="ProgressTracker__bar" />
        <span className="ProgressTracker__bar" />
      </div>
    </div>
    )
  );
}

ProgressTracker.defaultProps = {
  customerStatus: {},
  customerData: {},
};

ProgressTracker.propTypes = {
  step: PropTypes.number.isRequired,
  customerStatus: PropTypes.shape({
    error: PropTypes.string,
  }),
  customerData: PropTypes.shape({
    providers: PropTypes.arrayOf(PropTypes.string),
    rentOrOwn: PropTypes.string,
  }),
};

export default ProgressTracker;
