const Status = {
  SERVICEABLE: 'SERVICEABLE',
  UNSERVICEABLE: 'UNSERVICEABLE',
};

const Error = {
  RENTER: 'RENTER',
  ZIP: 'ZIP',
};

const Validation = {
  VALID: 'VALID',
  INVALID: 'INVALID',
};

const { protocol, host } = window.location;

/*
  Preview links register as a prod environment so image source url's are not routing
  correctly to be viewed in preview branches. This added check routes to the correct source url
*/
const isPreview = host.includes('amplifyapp');

const BaseUrl = (process.env.NODE_ENV === 'production' && !isPreview)
  ? 'https://leadform.solar.saveonenergy.com'
  : `${protocol}//${host}`;

export {
  Status, Error, Validation, BaseUrl,
};
