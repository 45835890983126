import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippy.js/react';
import Button from '../../Button';
import { Status, Validation } from '../../../constants';
import { beam } from '../../../utils/beam';
import ToolTipSvg from '../../ToolTipSvg';
import AddressInput from '../../Inputs/AddressInput';
import HomeType from '../../Inputs/HomeType';

function HomeInformation({
  step,
  setActiveSlide,
  activeSlide,
  customerStatus,
  setCustomerData,
  customerData,
}) {
  const { homeType, backupInterest, address } = customerData;
  const { street } = address;
  const { current, previous } = activeSlide;
  const { status, error } = customerStatus;
  const isServiceable = !error && status === Status.SERVICEABLE;
  const [valid, setValidate] = useState({ address: '', homeType: '', backupInterest: '' });
  const [buttonDisable, setButtonDisable] = useState(false);

  useEffect(() => {
    if (street
      && backupInterest
      && homeType) {
      setButtonDisable(true);
      return;
    }
    setButtonDisable(false);
  }, [street, backupInterest, homeType]);

  useEffect(() => {
    // Used in case a user blurs out of the address field and
    // clicks a energy type button which overrides state
    if (backupInterest) {
      setValidate({ ...valid, backupInterest: Validation.VALID });
    }
  }, [customerData]);

  const handleButtonChange = (e) => {
    beam('ElementClicked', {
      webElement: {
        location: 'LEAD FORM',
        position: '',
        elementType: 'BUTTON',
        text: e,
        htmlId: '',
      },
    });
    setCustomerData({ ...customerData, backupInterest: e });

    setValidate({ ...valid, backupInterest: Validation.VALID });
  };

  return (
    <form
      autoComplete="off"
      className={`slide
      ${step === previous && isServiceable ? 'is-inactive' : ''}
      ${step === current && isServiceable ? 'is-active' : ''}`}
    >
      <h2
        className="slide__heading slide__heading--cnet"
      >
        Tell us about your home
      </h2>
      <p
        className="slide__description slide__description--cnet"
      >
        This helps our solar partners find the right system for you.
      </p>
      <div className="slide__inputs">
        <AddressInput
          labelText="Address"
          type="address"
          placeHolder="Address"
          setValidate={setValidate}
          valid={valid}
          formValidation={valid.address}
          customerData={customerData}
          setCustomerData={setCustomerData}
        />
        <HomeType
          labelText="Home type"
          type="dropdown"
          placeHolder="Home type"
          setValidate={setValidate}
          valid={valid}
          formValidation={valid.homeType}
          setCustomerData={setCustomerData}
          customerData={customerData}
        />
      </div>
      <div>
        <p
          className="slide__question slide__question--home-info slide__question--cnet"
        >
          Are you interested in energy backup?
          <Tippy
            content={(
              <>
                <p className="tippy-paragraph">
                  With a solar battery, you can save excess energy generated by your solar panels to use later,
                  like at night or when it&apos;s cloudy.
                </p>
                <p>
                  With a solar generator, you can store energy to use later and also power your home during outages.
                </p>
              </>
          )}
            className="tippy tippy--cnet tippy--home-info"
            arrow
            trigger="click"
            placement="top"
          >
            <button
              aria-label="tooltip"
              className="tooltip-button-wrapper"
              type="button"
            >
              <span className="tooltip-icon">
                <ToolTipSvg color="var(--color-quaternary)" />
              </span>
            </button>
          </Tippy>
        </p>
        <div className="slide__buttons slide__buttons--home-info">
          <Button
            aria-label="battery"
            className="button--home-info"
            title="Battery"
            onChange={(e) => handleButtonChange(e)}
            value="BATTERY"
            isActive={backupInterest === 'BATTERY'}
          />
          <Button
            aria-label="generator"
            className="button--home-info"
            title="Generator"
            onChange={(e) => handleButtonChange(e)}
            value="GENERATOR"
            isActive={backupInterest === 'GENERATOR'}
          />
          <Button
            aria-label="no storage"
            className="button--home-info"
            title="No storage"
            onChange={(e) => handleButtonChange(e)}
            value="NO_STORAGE"
            isActive={backupInterest === 'NO_STORAGE'}
          />
          <Button
            aria-label="not sure"
            className="button--home-info"
            title="Not sure"
            onChange={(e) => handleButtonChange(e)}
            value="NOT_SURE"
            isActive={backupInterest === 'NOT_SURE'}
          />
        </div>
      </div>
      <Button
        aria-label="continue"
        type="submit"
        title="Continue"
        setActiveSlide={setActiveSlide}
        activeSlide={activeSlide}
        disabled={!buttonDisable}
      />
    </form>
  );
}

HomeInformation.defaultProps = {
  previous: 0,
  activeSlide: {},
  customerStatus: {},
  customerData: {},
  setCustomerData: () => {},
};

HomeInformation.propTypes = {
  previous: PropTypes.number,
  step: PropTypes.number.isRequired,
  setActiveSlide: PropTypes.func.isRequired,
  activeSlide: PropTypes.shape(),
  customerStatus: PropTypes.shape({
    status: PropTypes.string,
    error: PropTypes.string,
  }),
  customerData: PropTypes.shape({
    address: PropTypes.shape(),
    homeType: PropTypes.string,
    backupInterest: PropTypes.string,
  }),
  setCustomerData: PropTypes.func,
};

export default HomeInformation;
