import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Validation } from '../../constants';
import getSuggestions from '../../utils/addressAutoComplete';

function AddressAutoComplete({
  formValid,
  placeholder,
  handleAddressOnchange,
  handleAddressOnBlur,
  inputValue,
  id,
  customerData,
  setCustomerData,
  setInputValue,
}) {
  const [results, setResults] = useState([]);
  const [addressSelected, setAddressSelected] = useState(false);

  const autoSuggestRequest = async () => {
    if (inputValue.length >= 3 && !addressSelected) {
      const data = await getSuggestions(inputValue);

      if (data) {
        setResults(data);
        return;
      }

      setResults([]);
    } else if (inputValue.length) {
      setResults([]);
      setAddressSelected(false);
    }
  };

  useEffect(async () => {
    const timeout = setTimeout(autoSuggestRequest, 500);

    return () => clearTimeout(timeout);
  }, [inputValue]);

  const formatEntry = (row) => {
    const {
      // eslint-disable-next-line camelcase
      street_line,
      secondary,
      city,
      state,
      zipcode,
      entries,
    } = row;
    // eslint-disable-next-line camelcase
    const formattedEntry = `${street_line} ${secondary} (${entries}) ${city} ${state} ${zipcode}`;
    return formattedEntry;
  };

  const handleOnclick = async (row) => {
    const {
      // eslint-disable-next-line camelcase
      street_line,
      secondary,
      city,
      state,
      zipcode,
      entries,
    } = row;

    // Entries is in reference to multiple options per selection such as
    // an apartment building with multiple units
    if (entries && entries > 1) {
      const data = await getSuggestions(inputValue, formatEntry(row));

      if (data) {
        setResults(data);
        return;
      }

      setResults([]);
    } else {
      const formattedAddress = {
        address: {
          // eslint-disable-next-line
          street: street_line + (secondary || ''),
          zip: zipcode,
          city,
          state,
        },
      };
      setCustomerData({ ...customerData, ...formattedAddress });
      // eslint-disable-next-line camelcase
      setInputValue(`${street_line} ${secondary} ${city} ${state} ${zipcode}`);
      setAddressSelected(true);
    }
  };

  const handleKeyDown = (e, rowData) => {
    const key = e.keyCode || e.which;
    const space = 32;
    const enter = 13;
    const shift = 16;

    const triggerKeys = key === space || key === shift || key === enter;
    if (triggerKeys) {
      handleOnclick(rowData);
    }
  };

  return (
    <div className="AutoComplete__wrapper">
      <input
        className={`Input__input Input__input--cnet
          ${formValid === Validation.INVALID ? 'Input__input--error' : ''}`}
        id={id}
        type="text"
        placeholder={placeholder}
        autoComplete="off"
        onChange={(e) => handleAddressOnchange(e)}
        // Since a user clicking on an option from the dropdown
        // creates an 'onblur' effect we want to make sure we wait for the
        // the click event to happen before we determine if the field is valid
        onBlur={(e) => setTimeout(() => {
          handleAddressOnBlur(e);
        }, 400)}
        value={inputValue}
      />
      {!!results.length && (
      <ul className="AutoComplete">
        {
          // In case alot of entries are sent
          results.slice(0, 6).map((row) => (
            // eslint-disable-next-line
            <li
              className="AutoComplete__suggestion"
              onClick={() => handleOnclick(row)}
              key={row.secondary + Math.random()}
              onKeyDown={(e) => handleKeyDown(e, row)}
            >
              {row.street_line}
              {'. '}
              {
               row.entries !== 0 && row.entries !== 1 && (
               <>
                 (
                 {row.entries}
                 )
               </>
               )
              }
              {' '}
              {row.city}
              {' '}
              {row.state}
              {' , '}
              {row.zipcode}
            </li>
          ))
         }
      </ul>
      )}
    </div>
  );
}

AddressAutoComplete.defaultProps = {
  placeholder: '',
  id: 0,
  customerData: {},
  setCustomerData: () => {},
  setInputValue: () => {},
  handleAddressOnBlur: () => {},
  handleAddressOnchange: () => {},
  inputValue: '',
  formValid: '',
};

AddressAutoComplete.propTypes = {
  formValid: PropTypes.string,
  handleAddressOnchange: PropTypes.func,
  handleAddressOnBlur: PropTypes.func,
  inputValue: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  customerData: PropTypes.shape({
    address: PropTypes.shape(),
  }),
  setCustomerData: PropTypes.func,
  setInputValue: PropTypes.func,
};

export default AddressAutoComplete;
