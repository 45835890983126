import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AddressAutoComplete from '../../AddressAutoComplete';
import { Validation } from '../../../constants';
import { beam } from '../../../utils/beam';

function AddressInput({
  labelText,
  placeHolder,
  setValidate,
  valid,
  formValidation,
  customerData,
  setCustomerData,
}) {
  const [inputValue, setInputValue] = useState('');

  const handleAddress = (e) => {
    const { address } = customerData;

    const state = {
      address: {},
    };
    state.address.street = e;
    setCustomerData({ ...customerData, address: { ...customerData.address, street: e } });
    if (address) {
      setValidate({ ...valid, address: Validation.VALID });
    } else {
      setValidate({ ...valid, address: Validation.INVALID });
    }
  };

  const handleAddressOnchange = ({ target }) => {
    const { value } = target;
    setInputValue(value);
    handleAddress(value);
  };

  const handleAddressOnBlur = ({ target }) => {
    const { value } = target;

    beam('FieldInputted', {
      userInputField: {
        fieldType: 'ADDRESS',
        fieldName: 'ADDRESS',
        fieldValue: value,
      },
      formContext: {
        formType: 'HOME INFO',
        formName: 'HOME INFO',
      },
    });

    if (value) {
      setValidate({ ...valid, address: Validation.VALID });
    } else {
      setValidate({ ...valid, address: Validation.INVALID });
    }
  };

  return (
    <div className="Input">
      <span
        className="Input__label Input__label--cnet"
        htmlFor={labelText}
      >
        {labelText}
      </span>
      <AddressAutoComplete
        className={`Input__input Input__input--cnet
          ${formValidation === Validation.INVALID ? 'Input__input--error' : ''}`}
        id={labelText}
        type="text"
        placeholder={placeHolder}
        handleAddressOnchange={handleAddressOnchange}
        handleAddressOnBlur={handleAddressOnBlur}
        inputValue={inputValue}
        formValid={formValidation}
        customerData={customerData}
        setCustomerData={setCustomerData}
        setInputValue={setInputValue}
      />
    </div>
  );
}

AddressInput.defaultProps = {
  labelText: '',
  setValidate: () => {},
  valid: {},
  formValidation: '',
  customerData: {},
  setCustomerData: () => {},
};

AddressInput.propTypes = {
  placeHolder: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  setValidate: PropTypes.func,
  valid: PropTypes.shape(),
  formValidation: PropTypes.string,
  customerData: PropTypes.shape(),
  setCustomerData: PropTypes.func,
};

export default AddressInput;
