import PropTypes from 'prop-types';
import React from 'react';

function ToolTipSvg({ color }) {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Desktop"
        stroke="white"
        strokeWidth=".5px"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Choose-Color-and-Graphics"
          transform="translate(-164.000000, -536.000000)"
          fill={color || '#424242'}
          fillRule="nonzero"
        >
          <path
            d="M169.938222,536.546898 C170.897855,536.546898 171.785874,536.786806 172.602278,
            537.266622 C173.418683,537.746439 174.066793,538.394549 174.54661,539.210954 C175.026426,
            540.027358 175.266334,540.915377 175.266334,541.87501 C175.266334,542.834643 175.026426,
            543.722662 174.54661,544.539066 C174.066793,545.35547 173.418683,546.003581 172.602278,
            546.483397 C171.785874,546.963214 170.897855,547.203122 169.938222,547.203122 C168.978589,
            547.203122 168.09057,546.963214 167.274166,546.483397 C166.457762,546.003581 165.809651,
            545.35547 165.329835,544.539066 C164.850018,543.722662 164.61011,542.834643 164.61011,
            541.87501 C164.61011,540.915377 164.850018,540.027358 165.329835,539.210954 C165.809651,
            538.394549 166.457762,537.746439 167.274166,537.266622 C168.09057,536.786806 168.978589,
            536.546898 169.938222,536.546898 Z M169.938222,546.171875 C170.711658,546.171875 171.427802,
            545.978516 172.086655,545.591798 C172.745507,545.20508 173.268292,544.682295 173.65501,
            544.023442 C174.041728,543.36459 174.235087,542.648445 174.235087,541.87501 C174.235087,
            541.101574 174.041728,540.38543 173.65501,539.726577 C173.268292,539.067725 172.745507,
            538.54494 172.086655,538.158222 C171.427802,537.771504 170.711658,537.578145 169.938222,
            537.578145 C169.164786,537.578145 168.448642,537.771504 167.78979,538.158222 C167.130937,
            538.54494 166.608152,539.067725 166.221434,539.726577 C165.834716,540.38543 165.641357,
            541.101574 165.641357,541.87501 C165.641357,542.648445 165.834716,543.36459 166.221434,
            544.023442 C166.608152,544.682295 167.130937,545.20508 167.78979,545.591798 C168.448642,
            545.978516 169.164786,546.171875 169.938222,546.171875 Z M169.938222,538.910173 C170.181711,
            538.910173 170.392974,538.999691 170.57201,539.178727 C170.751046,539.357763 170.840564,
            539.569026 170.840564,539.812515 C170.840564,540.056004 170.751046,540.267266 170.57201,
            540.446302 C170.392974,540.625338 170.181711,540.714856 169.938222,540.714856 C169.694733,
            540.714856 169.483471,540.625338 169.304435,540.446302 C169.125399,540.267266 169.035881,
            540.056004 169.035881,539.812515 C169.035881,539.569026 169.125399,539.357763 169.304435,
            539.178727 C169.483471,538.999691 169.694733,538.910173 169.938222,538.910173 Z M171.141344,
            544.367191 C171.141344,544.438806 171.116279,544.499678 171.066149,544.549808 C171.016019,
            544.599938 170.955147,544.625003 170.883532,544.625003 L168.992912,544.625003 C168.921298,
            544.625003 168.860425,544.599938 168.810295,544.549808 C168.760165,544.499678 168.7351,
            544.438806 168.7351,544.367191 L168.7351,543.851568 C168.7351,543.779953 168.760165,
            543.719081 168.810295,543.668951 C168.860425,543.618821 168.921298,543.593756 168.992912,
            543.593756 L169.250724,543.593756 L169.250724,542.218759 L168.992912,542.218759 C168.921298,
            542.218759 168.860425,542.193694 168.810295,542.143564 C168.760165,542.093434 168.7351,
            542.032561 168.7351,541.960947 L168.7351,541.445323 C168.7351,541.373709 168.760165,
            541.312837 168.810295,541.262707 C168.860425,541.212577 168.921298,541.187511 168.992912,
            541.187511 L170.367909,541.187511 C170.439523,541.187511 170.500395,541.212577 170.550525,
            541.262707 C170.600655,541.312837 170.62572,541.373709 170.62572,541.445323 L170.62572,
            543.593756 L170.883532,543.593756 C170.955147,543.593756 171.016019,543.618821 171.066149,
            543.668951 C171.116279,543.719081 171.141344,543.779953 171.141344,543.851568 L171.141344,544.367191 Z"
            id="info-bubble"
          />
        </g>
      </g>
    </svg>
  );
}

ToolTipSvg.defaultProps = {
  color: '',
};

ToolTipSvg.propTypes = {
  color: PropTypes.string,
};

export default ToolTipSvg;
