import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEmail from 'validator/lib/isEmail';
import { Validation } from '../../../constants';
import { beam } from '../../../utils/beam';

function Email({
  labelText,
  placeHolder,
  setValidate,
  valid,
  formValidation,
  customerData,
  setCustomerData,
  customerStatus,
}) {
  const [inputValue, setInputValue] = useState('');
  const [touched, setTouched] = useState(false);

  const handleEmail = (value) => {
    setCustomerData({ ...customerData, email: value });

    if (isEmail(value)) {
      setValidate({ ...valid, email: Validation.VALID });
    } else {
      setValidate({ ...valid, email: Validation.INVALID });
    }
  };

  const handleEmailOnchange = ({ target }) => {
    const { value } = target;
    setInputValue(value);
    handleEmail(value);
  };

  const handleEmailOnBlur = ({ target }) => {
    const { value } = target;
    const unserviceAbleErrorCode = customerStatus.error === 'RENTER' ? 'RENTERS' : 'UNSERVICABILITY';

    if (isEmail(value)) {
      setValidate({ ...valid, email: Validation.VALID });
    } else {
      setValidate({ ...valid, email: Validation.INVALID });
    }

    beam('FieldInputted', {
      userInputField: {
        fieldType: 'EMAIL',
        fieldName: 'EMAIL',
        fieldValue: value,
      },
      formContext: {
        formType: !customerStatus.error ? 'PERSONAL INFO' : unserviceAbleErrorCode,
        formName: !customerStatus.error ? 'PERSONAL INFO' : unserviceAbleErrorCode,
      },
    });

    setTouched(true);
  };

  return (
    <div className="Input">
      <span
        className="Input__label Input__label--cnet"
        htmlFor={labelText}
      >
        {labelText}
      </span>
      <input
        className={`Input__input  Input__input--cnet
          ${touched && formValidation === Validation.INVALID ? 'Input__input--error' : ''}`}
        id={labelText}
        type="text"
        placeholder={placeHolder}
        onChange={(e) => handleEmailOnchange(e)}
        onBlur={(e) => handleEmailOnBlur(e)}
        value={inputValue}
        autoComplete="off"
      />
    </div>
  );
}

Email.defaultProps = {
  labelText: '',
  setValidate: () => {},
  valid: {},
  formValidation: '',
  customerData: {},
  setCustomerData: () => {},
  customerStatus: {},
};

Email.propTypes = {
  placeHolder: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  setValidate: PropTypes.func,
  valid: PropTypes.shape(),
  formValidation: PropTypes.string,
  customerData: PropTypes.shape(),
  setCustomerData: PropTypes.func,
  customerStatus: PropTypes.shape(),
};

export default Email;
