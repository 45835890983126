import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import Button from '../../Button';
import { Status, Validation } from '../../../constants';
import { beam, beamIdentify, beamLeadSubmit } from '../../../utils/beam';
import FirstName from '../../Inputs/FirstName';
import LastName from '../../Inputs/LastName';
import Email from '../../Inputs/Email';
import Phone from '../../Inputs/Phone';
import Checkbox from '../../Checkbox';

function CustomerInfo({
  step,
  setActiveSlide,
  activeSlide,
  customerStatus,
  setCustomerData,
  customerData,
}) {
  const {
    firstName,
    lastName,
  } = customerData;
  const { current, previous } = activeSlide;
  const { status, error } = customerStatus;
  const isServiceable = !error && status === Status.SERVICEABLE;
  const [tcpaIsChecked, setTcpaIsChecked] = useState(false);
  const [valid, setValidate] = useState({
    firstName: '', lastName: '', email: '', phone: '', tcpaConsent: tcpaIsChecked,
  });
  const [buttonDisable, setButtonDisable] = useState(true);

  const handleTcpa = (value) => {
    if (value) {
      setValidate({ ...valid, tcpaConsent: Validation.VALID });
      setTcpaIsChecked(!tcpaIsChecked);
    } else {
      setValidate({ ...valid, tcpaConsent: Validation.INVALID });
      setTcpaIsChecked(tcpaIsChecked);
    }
  };

  useEffect(() => {
    const enableButton = valid.email === Validation.VALID
      && firstName
      && lastName
      && valid.phone === Validation.VALID
      && valid.tcpaConsent === Validation.VALID;

    return enableButton ? setButtonDisable(false) : setButtonDisable(true);
  }, [valid]);

  const handleFormSubmit = () => {
    const correlationId = uuidv4();

    beamIdentify(customerData, { correlationId });

    beam('FormSubmitted', {
      formContext: {
        formType: 'LEAD FORM',
        formName: 'LEAD FORM',
      },
      correlationId,
    });

    setTimeout(() => {
      beamLeadSubmit(customerData);
    }, 3000);
  };

  return (
    <form
      autoComplete="off"
      className={`slide
      ${step === previous && isServiceable ? 'is-inactive' : ''}
      ${step === current && isServiceable ? 'is-active' : ''}`}
    >
      <h2
        className="slide__heading slide__heading--cnet"
      >
        Tell us about yourself
      </h2>
      <p
        className="slide__description slide__description--cnet"
      >
        Our solar partners will contact you about your options.
      </p>
      <div className="slide__inputs">
        <FirstName
          labelText="First name"
          placeHolder="First name"
          setValidate={setValidate}
          valid={valid}
          formValidation={valid.firstName}
          setCustomerData={setCustomerData}
          customerData={customerData}
        />
        <LastName
          labelText="Last name"
          customerStatus={customerStatus}
          placeHolder="Last name"
          setValidate={setValidate}
          valid={valid}
          formValidation={valid.lastName}
          customerData={customerData}
          setCustomerData={setCustomerData}
        />
      </div>
      <div className="slide__inputs">
        <Email
          labelText="Email"
          placeHolder="Email"
          setValidate={setValidate}
          valid={valid}
          formValidation={valid.email}
          customerData={customerData}
          setCustomerData={setCustomerData}
        />
        <Phone
          labelText="Phone number"
          placeHolder="Phone"
          setValidate={setValidate}
          valid={valid}
          formValidation={valid.phone}
          customerData={customerData}
          setCustomerData={setCustomerData}
        />
      </div>
      <Checkbox
        value={tcpaIsChecked}
        onChange={handleTcpa}
      >
        <>
          By checking this box, I consent to receiving text messages to my phone number
          provided above from Save On Energy, LLC or the chosen
          {' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.saveonenergy.com/solar-installers-list/"
            className="tcpa-link-text"
          >
            solar installer/s
          </a>
          {' '}
          to use the phone number entered for communication and marketing purposes,
          such as to send you product offers, using automated technology, even if I
          registered my number on a Do-Not-Call Registry. I have read the
          {' '}
          <a
            target="_blank"
            href="https://www.saveonenergy.com/terms-of-use/"
            className="tcpa-link-text"
          >
            Terms of Use
          </a>
          {' '}
          and
          {' '}
          <a
            target="_blank"
            href="https://www.saveonenergy.com/privacy/"
            className="tcpa-link-text"
          >
            Privacy Policy
          </a>
          .
          I understand that message and data rates may apply, that I do not have to consent to receiving text messages
          as a condition of purchasing goods and services, and that I may revoke my consent at any time.
        </>
      </Checkbox>
      <Button
        aria-label="submit"
        className="button__submit--customer-info"
        type="submit"
        title="Submit"
        setActiveSlide={setActiveSlide}
        activeSlide={activeSlide}
        disabled={buttonDisable}
        onChange={handleFormSubmit}
      />
    </form>
  );
}

CustomerInfo.defaultProps = {
  previous: 0,
  activeSlide: {},
  customerStatus: {},
  customerData: {},
};

CustomerInfo.propTypes = {
  previous: PropTypes.number,
  step: PropTypes.number.isRequired,
  setActiveSlide: PropTypes.func.isRequired,
  activeSlide: PropTypes.shape(),
  customerStatus: PropTypes.shape({
    status: PropTypes.string,
    error: PropTypes.string,
  }),
  setCustomerData: PropTypes.func.isRequired,
  customerData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
};

export default CustomerInfo;
