import React from 'react';
import PropTypes from 'prop-types';
import { Status } from '../../../constants';

function CommunitySolarRedirectStep({ activeSlide, step, customerStatus }) {
  const { current, previous } = activeSlide;
  const { status, error } = customerStatus;
  const isServiceable = !error && status === Status.SERVICEABLE;

  return (
    <div className={`slide slide__community-solar-redirect
     ${step === previous && isServiceable ? 'is-inactive' : ''}
     ${step === current && isServiceable ? 'is-active' : ''}`}
    >
      <h2
        className="slide__heading community-solar-heading"
      >
        We’re sending you to our Arcadia partner site to explore your community solar options.
      </h2>
    </div>
  );
}

CommunitySolarRedirectStep.defaultProps = {
  previous: 0,
  current: 0,
  activeSlide: {},
  customerStatus: {},
};

CommunitySolarRedirectStep.propTypes = {
  current: PropTypes.number,
  previous: PropTypes.number,
  step: PropTypes.number.isRequired,
  activeSlide: PropTypes.shape(),
  customerStatus: PropTypes.shape({
    status: PropTypes.string,
    error: PropTypes.string,
  }),
};

export default CommunitySolarRedirectStep;
