import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Email from '../../Inputs/Email';
import Button from '../../Button';
import { Status, Validation } from '../../../constants';
import { beam, beamLeadSubmit } from '../../../utils/beam';

function UnserviceableEmailStep({
  activeSlide,
  setActiveSlide,
  step,
  customerStatus,
  setCustomerData,
  customerData,
}) {
  const { current, previous } = activeSlide;
  const { status } = customerStatus;
  const [valid, setValidate] = useState({
    email: '',
  });
  const unserviceAble = status === Status.UNSERVICEABLE;
  const [buttonDisable, setButtonDisable] = useState(false);

  useEffect(() => {
    if (valid.email === Validation.VALID) {
      setButtonDisable(true);
      return;
    }
    setButtonDisable(false);
  }, [valid]);

  const handleFormSubmit = () => {
    beam('FormSubmitted', {
      formContext: {
        formType: 'EMAIL',
        formName: 'UNSERVICABILITY',
      },
    });

    beamLeadSubmit(customerData);
  };

  return (
    <div className={`slide
      slide__unserviceable-screen
     ${step === previous && unserviceAble ? 'is-inactive' : ''}
     ${step === current && unserviceAble ? 'is-active' : ''}`}
    >
      <h1 className="slide__heading unserviceable-heading">
        We don’t offer solar in your ZIP code yet
      </h1>
      <p className="slide__description slide__description--cnet">
        The companies we work with don’t currently serve your area.
        The good news is that solar service areas are expanding!
        We can let you know when it reaches your neighborhood.
      </p>
      <p className="slide__question slide__question--unserviceable slide__question--cnet">
        Enter your email to stay in touch
      </p>
      <div className="slide__email-group">
        <Email
          type="email"
          placeHolder="Email address"
          setValidate={setValidate}
          valid={valid}
          formValidation={valid.email}
          customerStatus={customerStatus}
          customerData={customerData}
          setCustomerData={setCustomerData}
        />
        <Button
          aria-label="submit"
          className="button__submit--unserviceable"
          type="submit"
          title="Submit"
          activeSlide={activeSlide}
          setActiveSlide={setActiveSlide}
          disabled={!buttonDisable}
          onChange={handleFormSubmit}
          customerStatus={customerStatus}
        />
      </div>
    </div>
  );
}

UnserviceableEmailStep.defaultProps = {
  previous: 0,
  current: 0,
  activeSlide: {},
  setActiveSlide: () => {},
  customerStatus: {},
  customerData: {},
  setCustomerData: () => {},
};

UnserviceableEmailStep.propTypes = {
  current: PropTypes.number,
  previous: PropTypes.number,
  step: PropTypes.number.isRequired,
  activeSlide: PropTypes.shape(),
  setActiveSlide: PropTypes.func,
  customerStatus: PropTypes.shape({
    status: PropTypes.string,
    error: PropTypes.string,
  }),
  customerData: PropTypes.shape({
    email: PropTypes.string,
  }),
  setCustomerData: PropTypes.func,
};

export default UnserviceableEmailStep;
