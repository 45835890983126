const API_KEY = process.env.NODE_ENV === 'development'
  ? '4ede310e-2aef-4b31-a488-cf4d8e42744f'
  : '481df2a5-b959-4d06-873b-4d70ad1dde23';
// eslint-disable-next-line max-len
const ENDPOINT = `https://predict${process.env.NODE_ENV === 'development' ? '.staging' : ''}.harbinger.redventures.io/serviceability/projected/zip`;

const zipLookUp = async (zip) => {
  const payload = process.env.NODE_ENV === 'development' ? {
    options: {
      dataVersion: 'v1',
      ignoreApiCache: true,
    },
  } : {};

  const data = {
    ...payload,
    zip,
  };

  const response = await fetch(ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': API_KEY,
    },
    body: JSON.stringify(data),
  }).then((results) => results.json()).then(
    (resp) => {
      if (resp?.providers.length) {
        const providerNames = resp.providers.map((provider) => provider.providerName);

        if (providerNames.includes('Test Provider') && providerNames.length === 1) {
          return false;
        }
        return providerNames;
      }
      return false;
    },
  ).catch(() => false);
  return response;
};
export default zipLookUp;
