import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Validation } from '../../constants';

const options = [
  {
    label: 'House',
    value: 'house',
  },
  {
    label: 'Townhome',
    value: 'townhome',
  },
  {
    label: 'Multi-family',
    value: 'multifamily',
  },
  {
    label: 'Condo',
    value: 'condo',
  },
  {
    label: 'Apartment',
    value: 'apartment',
  },
  {
    label: 'Manufactured',
    value: 'manufactured',
  },
];

// This is custom styles functionality exposed by 'react-select'
const customStyles = {
  menu: (provided) => ({
    ...provided,
    marginTop: '0px',
  }),
};

function Dropdown({ onChange, formValid }) {
  return (
    <>
      <Select
        options={options}
        className={`Dropdown ${formValid === Validation.INVALID ? 'Dropdown__input--error' : ''}`}
        classNamePrefix="Dropdown"
        styles={customStyles}
        onChange={(e) => onChange(e)}
      />
    </>
  );
}

Dropdown.defaultProps = {
  formValid: '',
};

Dropdown.propTypes = {
  formValid: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default Dropdown;
