import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

export default function Checkbox(props) {
  const {
    children,
    onChange,
    textClass,
    value,
  } = props;

  const input = useRef(null);

  useEffect(() => {
    const { current } = input;

    if (value && current) {
      current.setAttribute('checked', true);
    } else if (current) {
      current.removeAttribute('checked');
    }
  }, [value, input]);

  useEffect(() => {
    const listener = ({ target }) => onChange(target.checked);
    const { current } = input;
    if (current) {
      current.addEventListener('change', listener);
      return () => current.removeEventListener('change', listener);
    }
    return null;
  }, [onChange, input]);

  return (
    // eslint-disable-next-line
    <label
      className="checkbox-wrapper"
    >
      <input
        type="checkbox"
        ref={input}
        className="checkbox__input checkbox__input--cnet"
      />
      <span className="checkbox__label checkbox__label--cnet" />
      {children && <p className={`checkbox__input--text checkbox__input--text--cnet ${textClass || ''}`}>{children}</p>}
    </label>
  );
}

Checkbox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onChange: PropTypes.func.isRequired,
  textClass: PropTypes.string,
  value: PropTypes.bool.isRequired,
};

Checkbox.defaultProps = {
  children: '',
  textClass: '',
};
