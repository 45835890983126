import React from 'react';
import PropTypes from 'prop-types';
import CurrencyFormat from 'react-currency-format';
import Tippy from '@tippy.js/react';
import { Validation } from '../../../constants';
import { beam } from '../../../utils/beam';
import ToolTipSvg from '../../ToolTipSvg';

function BillAmount({
  className,
  labelText,
  placeHolder,
  setValidate,
  valid,
  formValidation,
  customerData,
  setCustomerData,
  toolTip,
}) {
  const handleBillAmount = (e) => {
    const bill = e;
    const sanitized = bill.split(',').join('');
    const isValid = sanitized !== '';
    setCustomerData({ ...customerData, typicalMonthlyElectricBill: Number(sanitized) });

    if (isValid) {
      setValidate({ ...valid, typicalMonthlyElectricBill: Validation.VALID });
    } else {
      setValidate({ ...valid, typicalMonthlyElectricBill: Validation.INVALID });
    }
  };

  const handleCurrencyOnchange = ({ target }) => {
    const { value } = target;
    const sanitized = value.replace(/\$/, '');

    handleBillAmount(sanitized);
  };

  const handleCurrencyOnBlur = ({ target }) => {
    const { value } = target;
    const isValid = value !== '';

    beam('FieldInputted', {
      userInputField: {
        fieldType: 'ELECTRIC BILL',
        fieldName: 'ELECTRIC BILL',
        fieldValue: value,
      },
      formContext: {
        formType: 'LEAD FORM',
      },
    });

    if (isValid) {
      setValidate({ ...valid, typicalMonthlyElectricBill: Validation.VALID });
    } else {
      setValidate({ ...valid, typicalMonthlyElectricBill: Validation.INVALID });
    }
  };

  return (
    <div className={`Input ${className}`}>
      <span
        className="Input__label Input__label--cnet"
        htmlFor={labelText}
      >
        {labelText}
        {toolTip && (
          <Tippy
            content={toolTip}
            className="tippy tippy--cnet tippy--bill-amount"
            arrow
            trigger="click"
            placement="top"
          >
            <button
              className="tooltip-button-wrapper"
              type="button"
              aria-label="tooltip"
            >
              <span className="tooltip-icon">
                <ToolTipSvg color="var(--color-quaternary)" />
              </span>
            </button>
          </Tippy>
        )}
      </span>
      <CurrencyFormat
        className={`Input__input Input__input--cnet
          ${formValidation === Validation.INVALID ? 'Input__input--error' : ''}`}
        id={labelText}
        type="text"
        placeholder={placeHolder}
        thousandSeparator
        decimalSeparator="."
        prefix="$ "
        onBlur={(e) => handleCurrencyOnBlur(e)}
        onChange={(e) => handleCurrencyOnchange(e)}
        autoComplete="off"
      />
    </div>
  );
}

BillAmount.defaultProps = {
  className: '',
  labelText: '',
  setValidate: () => {},
  valid: {},
  formValidation: '',
  customerData: {},
  setCustomerData: () => {},
  toolTip: '',
};

BillAmount.propTypes = {
  className: PropTypes.string,
  placeHolder: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  setValidate: PropTypes.func,
  valid: PropTypes.shape(),
  formValidation: PropTypes.string,
  customerData: PropTypes.shape(),
  setCustomerData: PropTypes.func,
  toolTip: PropTypes.string,
};

export default BillAmount;
