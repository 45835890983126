const getSuggestions = (query, selected) => {
  const key = '151931295502736746';
  const maxResults = 6;
  const multiOptions = selected || '';
  const search = query;
  // eslint-disable-next-line max-len
  const requestUrl = `https://us-autocomplete-pro.api.smartystreets.com/lookup?key=${key}&search=${search}&max_results=${maxResults}&selected=${multiOptions}`;

  if (!query) return '';

  return fetch(requestUrl, {
    method: 'GET',
  }).then((results) => results.json()).then(
    (data) => data.suggestions,
  ).catch((err) => {
    throw (err);
  });
};

export default getSuggestions;
