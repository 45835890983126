import React from 'react';
import PropTypes from 'prop-types';
import { Validation } from '../../../constants';
import { beam } from '../../../utils/beam';
import Dropdown from '../../Dropdown';

function HomeType({
  labelText,
  setValidate,
  valid,
  formValidation,
  customerData,
  setCustomerData,
}) {
  const handleHomeType = (value) => {
    const { homeType } = customerData;
    setCustomerData({ ...customerData, homeType: value });

    beam('FieldSelected', {
      userInputField: {
        fieldType: 'HOME TYPE',
        fieldName: 'HOME TYPE',
        fieldValue: value,
      },
      formContext: {
        formType: 'LEAD FORM',
        formName: 'LEAD FORM',
        formId: '',
      },
    });

    if (homeType) {
      setValidate({ ...valid, homeType: Validation.VALID });
    } else {
      setValidate({ ...valid, homeType: Validation.INVALID });
    }
  };

  const handleHomeTypeOnchange = ({ value }) => {
    handleHomeType(value);

    if (value) {
      setValidate({ ...valid, homeType: Validation.VALID });
    } else {
      setValidate({ ...valid, homeType: Validation.INVALID });
    }
  };

  return (
    <div className="Input">
      <span
        className="Input__label Input__label--cnet"
        htmlFor={labelText}
      >
        {labelText}
      </span>
      <Dropdown
        onChange={handleHomeTypeOnchange}
        formValid={formValidation}
      />
    </div>
  );
}

HomeType.defaultProps = {
  labelText: '',
  setValidate: () => {},
  valid: {},
  formValidation: '',
  customerData: {},
  setCustomerData: () => {},
};

HomeType.propTypes = {
  labelText: PropTypes.string,
  setValidate: PropTypes.func,
  valid: PropTypes.shape(),
  formValidation: PropTypes.string,
  customerData: PropTypes.shape(),
  setCustomerData: PropTypes.func,
};

export default HomeType;
