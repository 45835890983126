import React from 'react';
import PropTypes from 'prop-types';
import { Validation } from '../../constants';
import { beam } from '../../utils/beam';

function Button({
  className,
  title,
  type,
  setActiveSlide,
  activeSlide,
  onChange,
  value,
  isActive,
  formValidation,
  disabled,
}) {
  const submitButton = type === 'submit';
  const arcadiaBtn = type === 'arcadiaBtn';

  const handleOnClick = () => {
    if (submitButton) {
      if (!disabled) {
        if (activeSlide.current < 3) {
          beam('FormContinued', {
            formContext: {
              formType: 'LEAD FORM',
              formName: activeSlide.current === 1 ? 'GET STARTED' : 'CONTINUE',
              formId: '',
            },
          });
        }
      }
    }
    if (arcadiaBtn && activeSlide.current === 2) {
      beam('ElementClicked', {
        webElement: {
          location: 'LEAD FORM',
          position: 'ARCADIA REDIRECT CTA',
          elementType: 'BUTTON',
          text: 'CHECK AVAILABILITY',
          htmlId: '',
        },
      });
    }
    const { current, previous } = activeSlide;
    setActiveSlide({ current: current + 1, previous: previous + 1 });
    window.scrollTo({ top: 0, behavior: 'smooth' });

    onChange(value);
  };
  return (
    // eslint-disable-next-line
    <button
      type="button"
      className={`button button--cnet
                ${type ? 'button__submit' : ''}
                ${isActive ? 'is-active is-active--cnet' : ''}
                ${formValidation === Validation.INVALID ? 'button--error' : ''}
                ${className}`}
      onClick={() => handleOnClick()}
      disabled={disabled}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: title,
      }}
    />
  );
}

Button.defaultProps = {
  className: '',
  type: '',
  activeSlide: {},
  setActiveSlide: () => {},
  value: '',
  onChange: () => {},
  isActive: false,
  formValidation: '',
  disabled: false,
};

Button.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  setActiveSlide: PropTypes.func,
  activeSlide: PropTypes.shape(),
  value: PropTypes.string,
  onChange: PropTypes.func,
  isActive: PropTypes.bool,
  formValidation: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Button;
