import { v4 as uuidv4 } from 'uuid';
import { createHash } from 'crypto';

const beam = (eventName, data) => {
  if (window.tagular && window.cohesion) {
    window.cohesion('ready', () => {
      window.tagular('beam', eventName, {
        '@type': `redventures.usertracking.v3.${eventName}`,
        ...data,
      });
    });
  }
};

const customerDataTransformer = (customerData) => {
  const customerDataCopy = { ...customerData };

  if (customerData.phone) {
    customerDataCopy.phoneNumber = customerData.phone;
  }

  return customerDataCopy;
};

const beamLeadSubmit = (customerData) => {
  const serviceAble = customerData.firstName;
  let sanitized = '';

  if (customerData.phone) {
    const number = customerData.phone;
    const splitted = number.split('');
    splitted.forEach((e) => {
      if (Number(e) || (e !== ' ' && Number(e) === 0)) {
        sanitized += e;
      }
    });
    // eslint-disable-next-line no-param-reassign
    customerData.phone = sanitized;
  }

  if (customerData.typicalMonthlyElectricBill) {
    const numberSanitized = Number(customerData.typicalMonthlyElectricBill);
    // eslint-disable-next-line no-param-reassign
    customerData.typicalMonthlyElectricBill = numberSanitized;
  }

  const data = {
    contact: {
      ...customerDataTransformer(customerData),
    },
  };

  // This line is needed so the Make event doesn't error out because there are 2 providers fields
  delete data.contact.providers;

  window.tagular('beam', {
    '@type': 'solar.LeadCaptured.v4',
    ...data,
    leadId: uuidv4(),
    leadSource: window?.INLINE_DATA?.foreignReferrerUrl || '',
    providers: customerData.providers,
    tcpaAcceptance: !!serviceAble,
  });
};

const isNumber = (value) => (Number(value) || (value !== ' ' && Number(value) === 0));

const beamIdentify = (data, beamData = {}) => {
  const phone = data?.phone ? data.phone.split('').filter((digit) => isNumber(digit)).join('') : null;
  const userId = data?.email ? createHash('sha256').update(data.email).digest('hex') : null;

  beam('Identify', {
    '@type': 'core.Identify.v1',
    userId,
    traits: {
      firstName: data?.firstName,
      lastName: data?.lastName,
      phone,
      email: data?.email,
      address: {
        city: data?.address?.city,
        postalCode: data?.address?.zip,
        state: data?.address?.state,
        street: data?.address?.street,
      },
    },
    ...beamData,
  });
};

const beamFormViewed = (formType = 'Lead Form') => {
  const payload = {
    formContext: {
      formBrand: 'Solar',
      formId: uuidv4(),
      formName: 'Solar Lead Form',
      formType,
    },
  };

  beam('FormViewed', payload);
};

export {
  beam,
  beamIdentify,
  beamLeadSubmit,
  beamFormViewed,
};
